import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from '@mui/material';
import React from 'react';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { defaultThemeOptions } from '../../../theme/ThemeProviderWithTheme';
import _ from 'lodash';

export const theme = createTheme(
  _.merge(defaultThemeOptions, {
    palette: {
      primary: {
        main: '#1BC3A1',
      },
      secondary: {
        main: '#8FA89F',
        contrastText: '#33564A',
      },
    },
  } as ThemeOptions)
);

export default function ThemeProviderWithThemeFW({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
