import React from 'react';
import LinksFooter, {
  LinksFooterProps,
} from '../../../../../../components/App/footer/LinksFooter/LinksFooter';
import logo_footer from '../../../../assets/logo_structural_concept.svg';

import {
  AppBar,
  AppBarProps,
  Box,
  Link,
  Toolbar,
  alpha,
  styled,
} from '@mui/material';

const LinkStyled: typeof Link = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  textDecoration: 'underline',
  '&:hover': {
    color: alpha(theme.palette.secondary.contrastText, 0.8),
  },
})) as typeof Link;

function LinksFooterFW(props: LinksFooterProps) {
  return (
    <LinksFooter
      logoFooter={logo_footer}
      color="secondary"
      slots={{ LinkComponent: LinkStyled }}
    />
  );
}

export default LinksFooterFW;
